export default {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
            },
            {
                title: "Clientes",
                root: true,
                bullet: "dot",
                icon: "fas fa-user",
                submenu: [
                    {
                        title: "Clientes",
                        page: "cliente",
                    },
                    {
                        title: "Contratos pendentes",
                        page: "contrato-pendente",
                    },
                ],
            },
            {
                title: "Instalações",
                root: true,
                icon: "fas fa-store",
                page: "instalacao",
            },
            {
                title: "Produtos",
                root: true,
                icon: "fas fa-archive",
                page: "produto",
            },
            {
                title: "Condições",
                root: true,
                icon: "fas fa-file-alt",
                page: "condicao",
            },
            {
                title: "Parceiros",
                root: true,
                icon: "far fa-address-book",
                page: "parceiro",
            },
            {
                title: "Landing page",
                root: true,
                icon: "fas fa-pen",
                page: "landing-page",
            },
            {
                title: "Relatórios",
                root: true,
                icon: "fas fa-chart-pie",
                page: "empresa",
                bullet: "dot",
                submenu: [
                    {
                        title: "Faturamento",
                        page: "relatorio/relatorio01",
                    },
                    {
                        title: "Comissão",
                        page: "relatorio/relatorio02",
                    },
                ],
            },
            {
                title: "Acessos",
                root: true,
                bullet: "dot",
                icon: "fas fa-users",
                submenu: [
                    {
                        title: "Usuários",
                        page: "admin",
                    },
                    {
                        title: "Grupos de usuários",
                        page: "grupo",
                    },
                ],
            },
        ],
    },
};
