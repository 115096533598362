/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./app/router/Routes";
import { LayoutSplashScreen } from "./_metronic";

export default function App({ store, persistor }) {
    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <Routes />
            </PersistGate>
        </Provider>
    );
}
